import { ProtectedRouterModule, PublicRouterModule } from '@shared/types';

// Public Modules
import Error, { ErrorModuleContainer } from './Error';
import GuestMeeting from './GuestMeeting';
import GuestNotes from './GuestMeetingNotes';
import Login from './Login';
import OAuth2 from './OAuth2';
import ResetPassword from './ResetPassword';
import SignUp from './SignUp';

// Protected Modules
import Analytics, { AnalyticsModuleContainer } from './Analytics';
import AIAssociate, { MyChatsModuleContainer } from './MyChats';
import Bookmarks, { BookmarksModuleContainer } from './Bookmarks';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import ChangeWorkspace from './WorkspaceTransfer';
import Checkout, { CheckoutModuleContainer } from './Checkout';
import ConfirmEmailAlias from './ConfirmEmailAlias';
import Confirmations from './Confirmations';
import Credits, { CreditsModuleContainer } from './Credits';
import Dashboard from './Dashboard';
import DVTConnect, { DVTConnectModuleContainer } from './DVTConnect';
import Home from './Home';
import Integrations from './Integrations';
import Meeting, { MeetingModuleContainer } from './Meeting';
import MyAssignments, { MyAssignmentsModuleContainer } from './MyAssignments';
import MyAutomations, { MyAutomationsModuleContainer } from './MyAutomations';
import MyCommitments from './MyCommitments';
import Onboarding, { OnboardingModuleContainer } from './Onboarding';
import Settings, { SettingsModuleContainer } from './Settings';
import SharedWithMe, { SharedMeetingsModuleContainer } from './SharedWithMe';
import WorkspaceAutomations, { WorkspaceAutomationsModuleContainer } from './WorkspaceAutomations';
import Workstreams, { MyWorkstreamsModuleContainer } from './MyWorkstreams';

// Register Public Modules
const publicModules: PublicRouterModule[] = [
  Error,
  ResetPassword,
  OAuth2,
  Login,
  SignUp,
  GuestMeeting,
  GuestNotes,
];

// Register Private Modules
const protectedModules: ProtectedRouterModule[] = [
  AIAssociate,
  Analytics,
  Bookmarks,
  ChangeEmail,
  ChangePassword,
  ChangeWorkspace,
  Checkout,
  ConfirmEmailAlias,
  Confirmations,
  Credits,
  Dashboard,
  DVTConnect,
  Home,
  Integrations,
  Meeting,
  MyAssignments,
  MyAutomations,
  MyCommitments,
  Onboarding,
  Settings,
  SharedWithMe,
  WorkspaceAutomations,
  Workstreams,
];

const modules = [...publicModules, ...protectedModules];

// Prefetch Modules
if ('requestIdleCallback' in window) {
  window.requestIdleCallback(() => {
    async function preloadLazyModuleContainers() {
      await MeetingModuleContainer.preload();
      await MyChatsModuleContainer.preload();
      await MyAssignmentsModuleContainer.preload();
      await SharedMeetingsModuleContainer.preload();
      await BookmarksModuleContainer.preload();
      await OnboardingModuleContainer.preload();
      await CheckoutModuleContainer.preload();
      await ErrorModuleContainer.preload();
      await AnalyticsModuleContainer.preload();
      await MyAutomationsModuleContainer.preload();
      await WorkspaceAutomationsModuleContainer.preload();
      await MyWorkstreamsModuleContainer.preload();
      await SettingsModuleContainer.preload();
      await CreditsModuleContainer.preload();
      await DVTConnectModuleContainer.preload();
    }
    preloadLazyModuleContainers();
  });
}

export default modules;
