import { addBreadcrumb } from '@sentry/react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useState, useRef } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// GQL Queries and Types
import deleteMeetingMutation from '@shared/queries/DeleteMeeting.graphql';
import myMeetingsQuery from '@shared/queries/MyMeetingOverviewsPaginated.graphql';
import recordingMeetingAccessQuery from '@shared/queries/RecordingMeetingAccess.graphql';
import startLiveMeetingMutation from '@shared/queries/StartLiveMeeting.graphql';
import submitRecordingChunkMutation from '@shared/queries/SubmitMeetingRecordingChunks.graphql';
import { DeleteMeeting, DeleteMeetingVariables } from '@gql-types/DeleteMeeting';
import { PlanIDEnum, RecordDevices, RecordingMeetingAccess } from '@gql-types';
import { StartLiveMeeting, StartLiveMeetingVariables } from '@gql-types/StartLiveMeeting';
import {
  SubmitMeetingRecordingChunks,
  SubmitMeetingRecordingChunksVariables,
} from '@gql-types/SubmitMeetingRecordingChunks';
// App Shared Dialogs
import AgentCallDialog from '@shared/dialogs/AgentCallDialog';
import RecordingProhibitedDialog from '@shared/dialogs/RecordingProhibitedDialog';
import SearchModal from '@shared/dialogs/SearchModal';
import SuccessfullySubmittedRecording from '@shared/dialogs/SuccessfullySubmittedRecording';
import UploadMeetingModal from '@shared/dialogs/UploadMeetingModal';
import ShowVideoOnboardingDialog from '@shared/dialogs/ShowVideoOnboardingDialog';
import TutorialsDialog from '@shared/dialogs/TutorialsDialog';
// App Shared
import AppMissionControl from '@shared/components/AppMissionControl';
import { LocalMeetingRecorder, LocalMeetingRecorderChunk } from '@shared/components';
import { LocationState } from '@shared/types';
import { Routes } from '@shared/enums';
import { graphErrorHorsemen, isDev } from '@shared/utils';
import { useUserContext, useUserInterface } from '@shared/hooks';

export const AppBarActions: React.VFC = () => {
  /* #region  Hooks */
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory<LocationState>();
  const client = useApolloClient();
  const user = useUserContext();

  const {
    isOpenIntroduction: isOpenGettingStarted,
    isOpenTutorial,
    isOpenRecorder,
    isOpenUpload,
    isOpenAgentCallPlanner,
    update,
  } = useUserInterface();

  const recordingMeetingIdRef = useRef<string | null>(null);

  const [isRequestingLimits, setIsRequestingLimits] = useState(false);
  const [shownRecordingProhibitedDialog, setShownRecordingProhibitedDialog] = useState(false);
  const [shownSearchModal, setShownSearchModal] = useState(false);
  const [shownSubmissionCompleted, setShownSubmissionCompleted] = useState<string | null>(null);
  /* #endregion */

  /* #region  Mutations */
  const [startLiveMeeting, { loading: isStartingMeeting }] = useMutation<
    StartLiveMeeting,
    StartLiveMeetingVariables
  >(startLiveMeetingMutation);

  const [submitRecordingChunk] = useMutation<
    SubmitMeetingRecordingChunks,
    SubmitMeetingRecordingChunksVariables
  >(submitRecordingChunkMutation);

  const [deleteMeeting] = useMutation<DeleteMeeting, DeleteMeetingVariables>(deleteMeetingMutation);
  /* #endregion */

  /* #region  Handlers */
  const handleToggleSearchModal = (open: boolean) => () => {
    setShownSearchModal(open);
  };

  const handleToggleAgentCallPlanner = (open: boolean) => () => {
    update({ isOpenAgentCallPlanner: open });
  };

  const handleToggleRecordingProhibited = (open: boolean) => () => {
    setShownRecordingProhibitedDialog(open);
  };

  const handleToggleUploadModal = (open: boolean) => () => {
    update({ isOpenUpload: open });
  };

  const handleClickOnStartRecording = async () => {
    const userBillingAccessId = user.data?.me?.billingAccess?.id;
    const isPersonalPlan = userBillingAccessId === PlanIDEnum.PERSONAL;

    if (!userBillingAccessId || isPersonalPlan) {
      setIsRequestingLimits(true);

      const { data } = await client.query<RecordingMeetingAccess>({
        query: recordingMeetingAccessQuery,
        fetchPolicy: 'network-only',
      });

      const paymentCustomer = data.me?.paymentCustomer;
      const meetingsDurationSecondsLeft = paymentCustomer?.meetingsDurationSecondsLeft ?? 0;
      const canRecord = meetingsDurationSecondsLeft > 0;

      setIsRequestingLimits(false);

      if (canRecord) {
        update({ isOpenRecorder: true });
      } else {
        setShownRecordingProhibitedDialog(true);
      }
    } else {
      update({ isOpenRecorder: true });
    }
  };

  const handleCancelRecording = () => {
    update({ isOpenRecorder: false });
    addBreadcrumb({
      category: 'recorder',
      message: 'Recording canceled',
      level: 'info',
    });
  };

  const handleStartRecording = async (variables: StartLiveMeetingVariables) => {
    recordingMeetingIdRef.current = null;

    const { data } = await startLiveMeeting({ variables });

    if (!data?.startLiveMeeting?.meeting) {
      graphErrorHorsemen(data?.startLiveMeeting?.errors);

      addBreadcrumb({
        category: 'recorder',
        message: 'Recording start failed',
        level: 'warning',
      });

      return false;
    } else {
      recordingMeetingIdRef.current = data.startLiveMeeting.meeting.id;

      addBreadcrumb({
        category: 'recorder',
        message: 'Recording start success',
        level: 'info',
      });

      return true;
    }
  };

  const handleSubmitRecordingChunk = async (chunk: LocalMeetingRecorderChunk) => {
    if (!recordingMeetingIdRef.current) return;

    const file = new File([chunk.blob], `${chunk.title}.wav`);

    const { data } = await submitRecordingChunk({
      variables: {
        file,
        meetingId: recordingMeetingIdRef.current,
        startedAt: chunk.startedAt,
        finishedAt: chunk.finishedAt,
        isLastChunk: chunk.isRecordingCompleted,
      },
    });

    if (!data?.submitMeetingRecordingChunks?.success) {
      const error = graphErrorHorsemen(data?.submitMeetingRecordingChunks?.errors);
      window.onbeforeunload = null;
      history.replace(Routes.ErrorGeneric, {
        error: {
          title: 'There was an error during recording',
          message: error || 'Please try recording again',
        },
      });

      addBreadcrumb({
        category: 'recorder',
        message: 'Recording chunk submiting failed',
        level: 'warning',
      });
    }

    addBreadcrumb({
      category: 'recorder',
      message: 'Recording chunk submiting success',
      level: 'info',
    });

    if (chunk.isMaxDurationReached) return;
    // complete recording / submit
    if (chunk.isRecordingCompleted && data?.submitMeetingRecordingChunks?.meeting?.id) {
      update({ isOpenRecorder: false });
      setShownSubmissionCompleted(data.submitMeetingRecordingChunks.meeting.id);

      addBreadcrumb({
        category: 'recorder',
        message: 'Recording completed and submitted',
        level: 'info',
      });
    }
  };

  const handleDeleteRecord = async () => {
    update({ isOpenRecorder: false });

    if (!recordingMeetingIdRef.current) return;

    const meetingId = parseInt(recordingMeetingIdRef.current, 10);
    recordingMeetingIdRef.current = null;

    const result = await deleteMeeting({
      variables: { meetingId },
      update: (cache) => {
        cache.evict({ id: cache.identify({ __typename: 'DetailedMeetingType', id: meetingId }) });
        cache.gc();
      },
    });

    if (!result.data?.deleteMeeting?.success) {
      graphErrorHorsemen(result.data?.deleteMeeting?.errors);
      addBreadcrumb({
        category: 'recorder',
        message: 'Recording delete failed',
        level: 'warning',
      });
    } else {
      addBreadcrumb({
        category: 'recorder',
        message: 'Recording delete success',
        level: 'info',
      });
    }
  };

  const handleCloseMeetingSubmittedDialog = () => {
    setShownSubmissionCompleted(null);
    client.refetchQueries({ include: [myMeetingsQuery] });
  };
  /* #endregion */

  /* #region  Helpers */
  const isSmartMikeDeviceUser = user.data?.me?.philipsDevices?.smartmike ?? false;
  const isSmartMeetingDeviceUser = user.data?.me?.philipsDevices?.smartmeeting ?? false;
  const isSmartDeviceUser = isSmartMeetingDeviceUser || isSmartMikeDeviceUser;
  const deviceType = isSmartMikeDeviceUser ? RecordDevices.smartmike : RecordDevices.smartmeeting;
  const recordingDeviceType = isSmartDeviceUser ? deviceType : undefined;
  const recordingMaxDurationSeconds = isDev() ? 300 : 7200; // 5 minutes for dev, 2 hours for prod
  /* #endregion */

  return (
    <>
      <Box display="flex">
        {/* Begin: Mobile search button */}
        {isSmallScreen && (
          <IconButton onClick={handleToggleSearchModal(true)}>
            <SearchIcon />
          </IconButton>
        )}
        {/* End: Mobile search button */}

        <Box display={isOpenRecorder ? 'flex' : 'none'} alignItems="center" px={1}>
          <LocalMeetingRecorder
            restartRecordingAtMaxDuration
            maxDurationSeconds={recordingMaxDurationSeconds}
            forceRecordingStart={isOpenRecorder}
            recordingDeviceType={recordingDeviceType}
            initializingMeeting={isStartingMeeting}
            onCancelRecording={handleCancelRecording}
            onDataAvailable={handleSubmitRecordingChunk}
            onDeleteRecord={handleDeleteRecord}
            onStartRecording={handleStartRecording}
            onError={handleCancelRecording}
          />
        </Box>

        {(isStartingMeeting || isRequestingLimits) && (
          <Box display="flex" alignItems="center" pr={2}>
            <CircularProgress size={24} />
          </Box>
        )}

        {!isOpenRecorder && (
          <AppMissionControl
            onInviteSemblyAgent={handleToggleAgentCallPlanner(true)}
            onRequestMeetingUploading={handleToggleUploadModal(true)}
            onRequestMeetingRecording={handleClickOnStartRecording}
          />
        )}
      </Box>
      {/* Begin: Dialogs */}
      {shownSearchModal && <SearchModal onClose={handleToggleSearchModal(false)} />}
      {isOpenAgentCallPlanner && <AgentCallDialog onClose={handleToggleAgentCallPlanner(false)} />}
      {shownRecordingProhibitedDialog && (
        <RecordingProhibitedDialog onClose={handleToggleRecordingProhibited(false)} />
      )}
      {isOpenUpload && (
        <UploadMeetingModal
          onUpload={setShownSubmissionCompleted}
          onClose={handleToggleUploadModal(false)}
        />
      )}
      {shownSubmissionCompleted && (
        <SuccessfullySubmittedRecording
          open={!!shownSubmissionCompleted}
          meetingId={shownSubmissionCompleted}
          onClose={handleCloseMeetingSubmittedDialog}
        />
      )}
      {isOpenGettingStarted && <ShowVideoOnboardingDialog />}
      {isOpenTutorial && <TutorialsDialog />}
      {/* End: Modal Dialogs */}
    </>
  );
};

export default AppBarActions;
