import { Routes } from '@shared/enums';
import { ProtectedRouterModule } from '@shared/types';
import DashboardModuleContainer from './containers/DashboardModuleContainer';

export const DashboardModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.Home,
    render: () => <DashboardModuleContainer />,
  },
};

export default DashboardModule;
