import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, rectSwappingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { format } from 'date-fns';
import { useState } from 'react';
import { Link } from 'react-router-dom';
// Material UI
import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MaterialLink from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
// Material Icons
import AddCircleIcon from '@material-ui/icons/AddSharp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import DateRangeIcon from '@material-ui/icons/DateRangeSharp';
import MoreVertIcon from '@material-ui/icons/MoreHorizRounded';
import slackIntegrationImage from '../../../lib/assets/icon-slack-integration-24.svg';
// App Shared
import { GoogleMeet, PdfType } from '@sembly-ui';
import { Routes as R } from '@shared/enums';

interface Widget {
  id:
    | 'tasks'
    | 'meetings'
    | 'bookmarks'
    | 'recentChats'
    | 'banner'
    | 'smartFeedCustom'
    | 'smartFeedDefault'
    | 'smartFeedDesign';
  title: string;
  width: 'full' | 'half';
  isPinned: boolean;
}

const SortableWidget = ({
  widget,
  classes,
  onOpenMenu,
  onCloseWidget,
}: {
  widget: Widget;
  classes: any;
  onOpenMenu: (event: React.MouseEvent<HTMLElement>, widgetId: string) => void;
  onCloseWidget: (widgetId: string) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: widget.id,
  });

  const widgetContent = {
    tasks: (
      <div>
        <MaterialLink component={Link} to={R.MyAssignments}>
          <Typography gutterBottom variant="h6" color="textPrimary" display="inline">
            My Tasks
          </Typography>
        </MaterialLink>
        <Tabs
          classes={{ root: classes.tabsRoot, flexContainer: classes.tabsFlexContainer }}
          value={0}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab className={classes.tab} label="Upcoming" />
          <Tab className={classes.tab} label="Overdue" />
          <Tab className={classes.tab} label="Completed" />
        </Tabs>
        <List dense>
          <ListItem button>
            <ListItemIcon>
              <IconButton size="small">
                <CheckCircleOutlineIcon fontSize="small" color="action" />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary="Strategic Product Roadmap Consolidation Workshop" />
            <ListItemSecondaryAction>
              <Typography variant="body2" color="textSecondary">
                30 Jan
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <IconButton size="small">
                <CheckCircleOutlineIcon fontSize="small" color="action" />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary="Comprehensive Market Trends and Competitor Analysis Initiative" />
            <ListItemSecondaryAction>
              <IconButton size="small">
                <DateRangeIcon fontSize="small" color="action" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <IconButton size="small">
                <CheckCircleOutlineIcon fontSize="small" color="action" />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary="End-to-End Customer Experience Optimization Strategy" />
            <ListItemSecondaryAction>
              <IconButton size="small">
                <DateRangeIcon fontSize="small" color="action" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <IconButton size="small">
                <CheckCircleOutlineIcon fontSize="small" color="action" />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary="Integrated Cross-Team Collaboration Enhancement Plan" />
            <ListItemSecondaryAction>
              <Typography variant="body2" color="textSecondary">
                Tomorrow
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Box mx={1}>
          <Button size="small">
            <Box mx={0.6}>
              <Typography variant="body2" color="textSecondary">
                Show More
              </Typography>
            </Box>
          </Button>
        </Box>
      </div>
    ),
    meetings: (
      <div>
        <MaterialLink component={Link} to={R.Home}>
          <Typography gutterBottom variant="h6" color="textPrimary" display="inline">
            Meetings
          </Typography>
        </MaterialLink>
        <Tabs
          classes={{ root: classes.tabsRoot, flexContainer: classes.tabsFlexContainer }}
          value={0}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab className={classes.tab} label="Upcoming" />
          <Tab className={classes.tab} label="Completed" />
        </Tabs>

        <List dense>
          <ListItem button>
            <ListItemIcon>
              <GoogleMeet />
            </ListItemIcon>
            <ListItemText primary="Webapp Team Daily" secondary="Fri, 9:00 AM" />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Gil Makleff"
                  src="https://randomuser.me/api/portraits/men/20.jpg"
                />
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Alec Rogers"
                  src="https://randomuser.me/api/portraits/men/12.jpg"
                />
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Alec Rogers"
                  src="https://randomuser.me/api/portraits/women/29.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <GoogleMeet />
            </ListItemIcon>
            <ListItemText primary="Tasty Tuesday Snacktime" secondary="Fri, 2:00 PM" />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Alec Rogers"
                  src="https://randomuser.me/api/portraits/women/1.jpg"
                />
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Gil Makleff"
                  src="https://randomuser.me/api/portraits/men/14.jpg"
                />
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Alec Rogers"
                  src="https://randomuser.me/api/portraits/men/5.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <GoogleMeet />
            </ListItemIcon>
            <ListItemText primary="Design Review" secondary="Fri, 3:00 PM" />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Alec Rogers"
                  src="https://randomuser.me/api/portraits/women/3.jpg"
                />
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Gil Makleff"
                  src="https://randomuser.me/api/portraits/men/2.jpg"
                />
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Alec Rogers"
                  src="https://randomuser.me/api/portraits/men/1.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <GoogleMeet />
            </ListItemIcon>
            <ListItemText primary="Weekly Allhands" secondary="Fri, 4:00 PM" />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Alec Rogers"
                  src="https://randomuser.me/api/portraits/women/1.jpg"
                />
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Gil Makleff"
                  src="https://randomuser.me/api/portraits/men/14.jpg"
                />
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Alec Rogers"
                  src="https://randomuser.me/api/portraits/men/5.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
        </List>

        <Box mx={1}>
          <Button size="small">
            <Box mx={0.6}>
              <Typography variant="body2" color="textSecondary">
                Show More
              </Typography>
            </Box>
          </Button>
        </Box>
      </div>
    ),
    bookmarks: (
      <div>
        <MaterialLink component={Link} to={R.Bookmarks}>
          <Typography gutterBottom variant="h6" color="textPrimary" display="inline">
            My Bookmarks
          </Typography>
        </MaterialLink>
        <List dense>
          <ListItem button>
            <ListItemAvatar>
              <Avatar alt="Inna Khoma" src="https://randomuser.me/api/portraits/women/39.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Inna Khoma"
              secondary="And there is also like questions like popping up this week from customers, like, why can I. Like I had this option before, like why? I can see why I can record meetings. Now I'm on Android app, like, what is going on? I'm using Fireflies, but I really want to move to your app. Like, is there a possibility to do that? So that's also."
            />
          </ListItem>
          <ListItem button>
            <ListItemAvatar>
              <Avatar
                alt="Arwin Adityavarna"
                src="https://randomuser.me/api/portraits/men/19.jpg"
              />
            </ListItemAvatar>
            <ListItemText
              primary="Arwin Adityavarna"
              secondary="The traction still there, the interest still there. So I connected with some potential customers this week. They really keen to try our product. But again, as you already know, with bigger enterprise the hurdle is always about security and privacy. So there are more things that they want to learn from us. So the process might take a bit longer. But again, if we look at the positive side, the interest is there people actually more willing and more open to start adopt AI technology."
            />
          </ListItem>
        </List>
      </div>
    ),
    smartFeedCustom: (
      <div>
        <Typography gutterBottom variant="h6" color="textPrimary" display="inline">
          Smart Feed: Company Financial Highlights
        </Typography>
        <List dense>
          <ListItem button>
            <ListItemText
              primary="2024 Revenue"
              secondary="Realized revenue reached $1.5M (up from $518K in 2023). Growth driven by increased corporate clients and subscriptions."
            />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  alt="Gil Makleff"
                  src="https://randomuser.me/api/portraits/men/34.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Annual Recurring Revenue (ARR)"
              secondary="Achieved $1.75M in 2024."
            />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Gil Makleff"
                  src="https://randomuser.me/api/portraits/men/31.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Monthly Recurring Revenue (MRR)"
              secondary="Approximately $144K. Efforts underway to address churn and improve retention."
            />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Gil Makleff"
                  src="https://randomuser.me/api/portraits/men/31.jpg"
                />
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Alec Rogers"
                  src="https://randomuser.me/api/portraits/men/34.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="StartEngine Campaign"
              secondary="Expected to launch next week to attract new investments."
            />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Gil Makleff"
                  src="https://randomuser.me/api/portraits/men/31.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Divider />
        <Box mt={2} display="flex" alignItems="center" gridGap={8}>
          <Typography variant="body2" color="textSecondary">
            Source
          </Typography>
          <Chip
            size="small"
            variant="outlined"
            label="All Meetings"
            style={{ padding: '4px 8px', overflow: 'hidden', color: 'rgba(0, 0, 0, 0.54)' }}
          />
        </Box>
      </div>
    ),
    smartFeedDesign: (
      <div>
        <Typography gutterBottom variant="h6" color="textPrimary" display="inline">
          Smart Feed: Product Design Process
        </Typography>
        <List dense>
          <ListItem button>
            <ListItemText
              primary="User Interaction Enhancements"
              secondary="Discussed improvements for chat context menu and bulk actions, including dropdown filters, custom date ranges, and toggle behaviors."
            />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  alt="Yury Trushchenkov"
                  src="https://randomuser.me/api/portraits/men/17.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="UX Feedback on Filters"
              secondary="Proposals to simplify UI by replacing toggles with buttons for clearer interactions. Considered lazy loading vs pagination for better performance."
            />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Yury Trushchenkov"
                  src="https://randomuser.me/api/portraits/men/17.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Bulk Actions Improvements"
              secondary="Added checkbox columns with bulk options for removing or adding meetings to chat. Discussed clearer indicators for selected items."
            />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Yury Trushchenkov"
                  src="https://randomuser.me/api/portraits/men/17.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Next Steps"
              secondary="Focused on aligning design elements, removing unused placeholders, and preparing for streamlined testing."
            />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Oleksiy Vlasov"
                  src="https://randomuser.me/api/portraits/men/39.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Divider />
        <Box mt={2} display="flex" alignItems="center" gridGap={8}>
          <Typography variant="body2" color="textSecondary">
            Source
          </Typography>
          <Chip
            size="small"
            variant="outlined"
            label="Webapp Design Weekly"
            style={{ padding: '4px 8px', overflow: 'hidden', color: 'rgba(0, 0, 0, 0.54)' }}
            icon={<GoogleMeet fontSize="small" style={{ marginRight: 1 }} />}
          />
        </Box>
      </div>
    ),
    smartFeedDefault: (
      <div>
        <Typography gutterBottom variant="h6" color="textPrimary" display="inline">
          Key Updates Relevant to Your Role
        </Typography>

        <List dense>
          <ListItem button>
            <ListItemText
              primary="User Engagement"
              secondary="13,500 hours logged on the platform this week, with 15,200 meetings processed."
            />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  alt="Artem Koren"
                  src="https://randomuser.me/api/portraits/men/28.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Global Data Compliance"
              secondary="Proposed solution for EU data storage aligns with client needs and reduces costs, boosting enterprise sales potential."
            />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Gil Makleff"
                  src="https://randomuser.me/api/portraits/men/31.jpg"
                />
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Arwen Adityavarna"
                  src="https://randomuser.me/api/portraits/men/32.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Client Adoption"
              secondary="Significant growth in accounts: Brookman Group added licenses, Apps Associates expanded from 10 to 60 users."
            />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Arwen Adityavarna"
                  src="https://randomuser.me/api/portraits/men/32.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Feature Feedback"
              secondary="Strong interest in voice ID and offline recording features; enhanced analytics requested for team usage."
            />
            <ListItemSecondaryAction>
              <AvatarGroup max={3}>
                <Avatar
                  className={classes.smallAvatar}
                  sizes="small"
                  alt="Inna Khoma"
                  src="https://randomuser.me/api/portraits/women/1.jpg"
                />
              </AvatarGroup>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Divider />
        <Box mt={2} display="flex" alignItems="center" gridGap={8}>
          <Typography variant="body2" color="textSecondary">
            Source
          </Typography>
          <Chip
            size="small"
            variant="outlined"
            label="All Meetings"
            style={{ padding: '4px 8px', overflow: 'hidden', color: 'rgba(0, 0, 0, 0.54)' }}
          />
        </Box>
      </div>
    ),
    recentChats: (
      <div>
        <MaterialLink component={Link} to={R.MyChats}>
          <Typography gutterBottom variant="h6" color="textPrimary" display="inline">
            Recent Chats
          </Typography>
        </MaterialLink>
        <Box mt={0.5} mx={0} py={1}>
          <Button size="small">
            <Box display="flex" alignItems="center" gridGap={4}>
              <AddCircleIcon fontSize="small" />
              <Typography component="span" variant="body2" color="textSecondary">
                Create New Chat
              </Typography>
            </Box>
          </Button>
        </Box>
        <Divider />
        <List dense>
          <ListItem button>
            <ListItemText primary="MVP Validation Planner" secondary="Mon, 9:00 AM" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Churn Reduction Strategist" secondary="Tue, 2:00 PM" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Pricing Model Optimizer" secondary="Wed, 3:00 PM" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Retention KPI Forecaster " secondary="Thu, 4:00 PM" />
            <ListItemSecondaryAction>
              <Chip
                clickable
                size="small"
                variant="outlined"
                label="Research Report"
                style={{ padding: '4px 8px', overflow: 'hidden' }}
                onClick={() => null}
                icon={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white',
                      borderRadius: 4,
                      height: 16,
                      width: 16,
                      fontSize: 12,
                      marginLeft: 8,
                      backgroundColor: '#B443B0',
                    }}
                  >
                    <PdfType fontSize="inherit" />,
                  </div>
                }
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Box mx={1}>
          <Button size="small">
            <Box mx={0.6}>
              <Typography variant="body2" color="textSecondary">
                Show More
              </Typography>
            </Box>
          </Button>
        </Box>
      </div>
    ),
    banner: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 8,
          background: 'linear-gradient(45deg, #ffc000, #ff8c00, #02bfbf, #7e5aff)',
          backgroundSize: '300% 300%',
          animation: 'gradientAnimation 10s ease infinite',
          margin: -16,
          borderRadius: 8,
          padding: '24px 48px 24px 36px',
          color: 'white',
        }}
      >
        <div>
          <Typography gutterBottom variant="h6" color="inherit">
            Maximize Your Productivity
          </Typography>
          <Typography variant="body1" color="textPrimary">
            We noticed you're using Slack. Connect Sembly with Slack to unlock seamless
            collaboration and powerful automation tools!
          </Typography>
        </div>
        <Button
          variant="contained"
          size="large"
          style={{ alignSelf: 'flex-end' }}
          startIcon={<img src={slackIntegrationImage} alt="Slack Integration" />}
          onClick={() => null}
        >
          Connect Slack
        </Button>
      </div>
    ),
  };

  const style: React.CSSProperties = {
    transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined,
    transition,
    opacity: isDragging ? 0.9 : 1,
    boxShadow: isDragging ? '0 10px 20px rgba(0, 0, 0, 0.15)' : '0 1px 3px rgba(0, 0, 0, 0.05)',
    zIndex: isDragging ? 100 : 'auto',
    position: isDragging ? 'relative' : 'static',
    backgroundColor: isDragging ? '#f0f8ff' : 'white',
    cursor: widget.isPinned ? 'default' : isDragging ? 'grabbing' : 'grab',
  };

  return (
    <Paper
      ref={setNodeRef}
      {...attributes}
      style={style}
      className={`${classes.widget} ${
        widget.width === 'full' ? classes.fullWidth : classes.halfWidth
      }`}
    >
      <div
        style={{ display: 'flex', position: 'relative' }}
        {...(widget.isPinned ? {} : listeners)}
      >
        {!widget.isPinned ? (
          <IconButton
            className={classes.menuButton}
            onClick={(e) => {
              e.stopPropagation();
              onOpenMenu(e, widget.id);
            }}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        ) : (
          <IconButton
            className={classes.menuButton}
            onClick={(e) => {
              e.stopPropagation();
              onCloseWidget(widget.id);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
        <div style={{ width: '100%' }}>{widgetContent[widget.id]}</div>
      </div>
    </Paper>
  );
};

const DashboardPage: React.FC = () => {
  const classes = useStyles();

  const [addMenuAnchor, setAddMenuAnchor] = useState<null | HTMLElement>(null);

  const [widgets, setWidgets] = useState<Widget[]>([
    { id: 'meetings', title: 'Meetings', width: 'half', isPinned: false },
    {
      id: 'smartFeedDefault',
      title: 'Key Updates Relevant to Your Role',
      width: 'half',
      isPinned: false,
    },
    { id: 'tasks', title: 'My Tasks', width: 'full', isPinned: false },
    { id: 'banner', title: 'Maximize Your Productivity', width: 'full', isPinned: true },
    { id: 'recentChats', title: 'Recent Chats', width: 'half', isPinned: false },
    {
      id: 'smartFeedDesign',
      title: 'Smart Feed: Product Design Process',
      width: 'half',
      isPinned: false,
    },
  ]);

  const widgetDefinitions: Widget[] = [
    { id: 'bookmarks', title: 'My Bookmarks', width: 'half', isPinned: false },
    { id: 'tasks', title: 'My Tasks', width: 'half', isPinned: false },
    { id: 'meetings', title: 'Meetings', width: 'half', isPinned: false },
    {
      id: 'smartFeedDefault',
      title: 'Key Updates Relevant to Your Role',
      width: 'half',
      isPinned: false,
    },
    {
      id: 'smartFeedCustom',
      title: 'Smart Feed: Company Financial Highlights',
      width: 'half',
      isPinned: false,
    },
    {
      id: 'smartFeedDesign',
      title: 'Smart Feed: Product Design Process',
      width: 'half',
      isPinned: false,
    },
    { id: 'recentChats', title: 'Recent Chats', width: 'half', isPinned: false },
  ];

  const handleAddMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAddMenuAnchor(event.currentTarget);
  };

  const handleAddMenuClose = () => {
    setAddMenuAnchor(null);
  };

  const handleAddWidget = (widget: Widget) => {
    setWidgets((prev) => [...prev, widget]);
    handleAddMenuClose();
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setWidgets((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over?.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [periodMenuAnchor, setPeriodMenuAnchor] = useState<null | HTMLElement>(null);
  const [period, setPeriod] = useState<'week' | 'month'>('week');
  const [selectedWidget, setSelectedWidget] = useState<string | null>(null);

  const onOpenMenu = (event: React.MouseEvent<HTMLElement>, widgetId: string) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedWidget(widgetId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedWidget(null);
  };

  const toggleWidgetSize = (widgetId: string, size: 'full' | 'half') => {
    setWidgets((prev) =>
      prev.map((widget) => (widget.id === widgetId ? { ...widget, width: size } : widget)),
    );
    handleMenuClose();
  };

  const generateGreeting = (name: string): { date: string; greeting: string } => {
    const now = new Date();
    const date = format(now, 'EEEE, MMMM d');
    const hour = now.getHours();
    const greeting = hour < 12 ? 'Good morning' : hour < 18 ? 'Good afternoon' : 'Good evening';
    return { date, greeting: `${greeting}, ${name}` };
  };

  const { date, greeting } = generateGreeting('Yuriy');

  return (
    <Container className={classes.container}>
      <Box
        mt={4}
        mb={2}
        display="flex"
        flexDirection="column"
        alignContent="center"
        justifyContent="center"
      >
        <Typography gutterBottom align="center" variant="subtitle1" color="textSecondary">
          {date}
        </Typography>
        <Typography align="center" variant="h2">
          {greeting}
        </Typography>
      </Box>

      <div className={classes.infoBar}>
        <Button color="inherit" size="small" onClick={(e) => setPeriodMenuAnchor(e.currentTarget)}>
          <Box display="flex" alignItems="center" gridGap={4} mx={1}>
            <Typography component="span" variant="body2" display="inline">
              {period === 'week' ? 'This Week' : 'This Month'}
            </Typography>
            <ArrowDropDownIcon fontSize="small" />
          </Box>
        </Button>

        <Divider
          orientation="vertical"
          light
          flexItem
          style={{ backgroundColor: 'currentcolor', opacity: 0.25 }}
        />

        <Box mx={1}>
          <Typography variant="body2" color="inherit" display="inline">
            <b>8</b> completed meetings
          </Typography>
        </Box>

        <Divider
          orientation="vertical"
          light
          flexItem
          style={{ backgroundColor: 'currentcolor', opacity: 0.25 }}
        />

        <Box mx={1}>
          <Typography variant="body2" color="inherit" display="inline">
            <b>4</b> remaining meetings
          </Typography>
        </Box>

        <Button
          disableElevation
          color="default"
          variant="contained"
          style={{ position: 'absolute', right: 12 }}
          startIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#00B3B3"
              width={16}
              height={16}
              viewBox="0 0 24 24"
            >
              <path d="M21 13.1C20.9 13.1 20.7 13.2 20.6 13.3L19.6 14.3L21.7 16.4L22.7 15.4C22.9 15.2 22.9 14.8 22.7 14.6L21.4 13.3C21.3 13.2 21.2 13.1 21 13.1M19.1 14.9L13 20.9V23H15.1L21.2 16.9L19.1 14.9M21 9H13V3H21V9M13 18.06V11H21V11.1C20.24 11.1 19.57 11.5 19.19 11.89L13 18.06M11 13H3V3H11V13M11 21H3V15H11V21Z" />
            </svg>
          }
          onClick={handleAddMenuOpen}
        >
          <Typography variant="body2">Customize</Typography>
        </Button>
      </div>

      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext items={widgets.map((widget) => widget.id)} strategy={rectSwappingStrategy}>
          <div className={classes.widgetContainer}>
            {widgets.map((widget) => (
              <SortableWidget
                key={widget.id}
                widget={widget}
                classes={classes}
                onOpenMenu={onOpenMenu}
                onCloseWidget={(widgetId) =>
                  setWidgets((prev) => prev.filter((w) => w.id !== widgetId))
                }
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>

      <Menu
        anchorEl={periodMenuAnchor}
        open={Boolean(periodMenuAnchor)}
        onClose={() => setPeriodMenuAnchor(null)}
        getContentAnchorEl={null}
        classes={{ paper: classes.menu }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem onClick={() => setPeriod('week')}>
          <ListItemIcon>{period === 'week' && <CheckIcon />}</ListItemIcon>
          This Week
        </MenuItem>
        <MenuItem onClick={() => setPeriod('month')}>
          <ListItemIcon>{period === 'month' && <CheckIcon />}</ListItemIcon>
          This Month
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        classes={{ paper: classes.menu }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem
          onClick={() => {
            setWidgets((prev) => prev.filter((w) => w.id !== selectedWidget));
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <span />
          </ListItemIcon>
          Remove
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => toggleWidgetSize(selectedWidget!, 'full')}>
          <ListItemIcon>
            {widgets.find((w) => w.id === selectedWidget)?.width === 'full' && <CheckIcon />}
          </ListItemIcon>
          Full Size
        </MenuItem>
        <MenuItem onClick={() => toggleWidgetSize(selectedWidget!, 'half')}>
          <ListItemIcon>
            {widgets.find((w) => w.id === selectedWidget)?.width === 'half' && <CheckIcon />}
          </ListItemIcon>
          Half Size
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={addMenuAnchor}
        open={Boolean(addMenuAnchor)}
        onClose={handleAddMenuClose}
        getContentAnchorEl={null}
        classes={{ paper: classes.menu }}
      >
        {widgetDefinitions.map((widget) => (
          <MenuItem
            key={widget.id}
            onClick={() => handleAddWidget(widget)}
            disabled={widgets.some((w) => w.id === widget.id)}
          >
            {widget.title}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
  },
  widget: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
    cursor: 'grab',
  },
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '49%',
  },
  menu: {
    borderRadius: theme.shape.borderRadius * 2,
  },
  menuButton: {
    position: 'absolute',
    top: -12,
    right: -12,
    zIndex: 100,
    cursor: 'pointer',
  },
  widgetContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  tab: {
    minWidth: 'auto',
    minHeight: 32,
    textTransform: 'none',
    padding: 0,
    fontSize: theme.typography.body2.fontSize,
  },
  tabsRoot: {
    minHeight: 32,
  },
  tabsFlexContainer: {
    gap: theme.spacing(2),
  },
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  infoBar: {
    borderRadius: '2em',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.surface.light,
    padding: theme.spacing(1, 3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(5),
    justifySelf: 'center',
    gap: theme.spacing(1),
  },
}));

export default DashboardPage;
