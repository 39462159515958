import { Redirect, Route, Switch } from 'react-router-dom';
// App Shared
import { Routes as R } from '@shared/enums';
import { MainPageLayout } from '@shared/layouts';
// Module Shared
import DashboardPage from '../routes/DashboardPage';

export const DashboardModuleContainer: React.VFC = () => {
  return (
    <MainPageLayout>
      <Switch>
        <Route path={R.Home} exact component={DashboardPage} />
        <Redirect from={R.Home} to={R.PageNotFound} />
      </Switch>
    </MainPageLayout>
  );
};

export default DashboardModuleContainer;
